/* Base Rule */
@font-face {
  font-family: "Haurabold", sans-serif;
  src: url("../public/hauora_sans/fonts/otf/Hauora-Bold.otf");
}

@font-face {
  font-family: "Haurasemibold", sans-serif;
  src: url("../public/hauora_sans/fonts/otf/Hauora-SemiBold.otf");
}

@font-face {
  font-family: "Hauramedium", sans-serif;
  src: url("../public/hauora_sans/fonts/otf/Hauora-Medium.otf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

/* html {
  font-size: 62.5%;
} */

body {
  margin: 0;
  padding: 0;
  background-color: var(--light-bg-color);
  color: var(--light-color);
  font-family: "Hauramedium", sans-serif !important;
  min-width: 100%;
}

h1 {
  font-family: "Hauramedium", sans-serif !important;
  font-size: 5.4rem;
  line-height: 1.1;
  font-weight: bold;
}

li,
input,
textarea {
  font-family: "Hauramedium", sans-serif !important;
  font-size: 1.125rem;
  line-height: 1.56;
  letter-spacing: 0.1rem;
  word-spacing: 0.06rem;
  border: none;
}

a {
  font-weight: 500;
  font-size: 1.8rem;
  letter-spacing: 0.02rem;
  color: var(--header-navlink-color);
  text-decoration: inherit;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

ul li {
  list-style: none;
}

/* Add list-style for visibility */
ul li {
  list-style: disc inside;
}

option {
  font-size: 1.6rem;
}

header {
  width: 100%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

b {
  color: var(--dark-link-color);
}

section {
  box-shadow: 0px 2px 4px 1px var(--box-shadow-color);
}

input::placeholder {
  color: var(--placeholder-color);
  font-size: 1.125rem;
}

thead:has(.tableheader) {
  border-top-left-radius: 1rem !important;
}

thead tr {
  text-align: center !important;
}

th {
  font-size: 1.5rem;
}

td {
  font-size: 1.5rem;
}

tbody tr td {
  text-align: center !important;
  padding: 0.8rem !important;
}

/* WebKit Browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 0.2rem;
}

::-webkit-scrollbar-track {
  background: var(--header-navlink-color)
}

::-webkit-scrollbar-thumb {
  background-color: var(--brand-color);
  border-radius: 10rem;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--btnhover-color);
}

/* Responsive Design Adjustments */
@media (max-width: 1200px) {
  html {
    font-size: 75%;
  }

  h1 {
    font-size: 4.8rem;
  }

  li,
  input,
  textarea,
  a {
    font-size: 1.6rem;
  }
}

@media (max-width: 768px) {
  html {
    font-size: 70%;
  }

  h1 {
    font-size: 4rem;
  }

  li,
  input,
  textarea,
  a {
    font-size: 1.4rem;
  }

  header {
    padding: 0 1.5rem;
  }
}

@media (max-width: 480px) {
  html {
    font-size: 65%;
  }

  h1 {
    font-size: 3.6rem;
  }

  li,
  input,
  textarea,
  a {
    font-size: 1.2rem;
  }

  header {
    padding: 0 1rem;
  }
}