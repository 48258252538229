.courseImg {
  width: 20vw;
  height: 30vh;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

/* Text editor class name style for contributions */
.rdw-dropdown-selectedtext {
  font-size: 18px;

}

.rdw-dropdownoption-default {
  font-size: 14px;
}

.rdw-dropdown-selectedtext {
  font-size: 18px;
}

.rdw-editor-toolbar {
  border-radius: 10px;
}

.DraftEditor-root {
  border-radius: 10px;
  padding: 10px;
}

/* 
 .MuiDataGrid-columnHeaders {
    background-color: rgb(216, 216, 216),
  }
.MuiDataGrid-columnHeaderTitle {
    color: "black",
   
  }
  .MuiDataGrid-columnHeader {
    background-color: "rgb(216, 216, 216)",
  }
  .MuiDataGrid-columnHeader--sortable:hover {
    background-color: "rgb(200, 200, 200)",
  } */





/* For navbar css start */

.main-nav .main-nav-btn {
  color: #37668F;
  background-color: #f0f0f0;
  margin: 5px;
  border-radius: 4px;
  margin-right: 2%;

}

.main-nav .mainnav-active-btn {
  color: #37668F;
  background-color: #f0f0f0;
  margin: 5px;
  border-radius: 4px;
  margin-right: 2%;

}

/* Add styles for sub navigation buttons */
.sub-nav .sub-nav-btn {
  color: #000;
  background-color: #e0e0e0;
  margin: 5px;
  border-radius: 4px;
  margin-right: 2%;
}

.sub-nav .subnav-active-btn {
  color: #37668F;
  background-color: #f0f0f0;
  margin: 5px;
  border-radius: 4px;
  margin-right: 2%;
}

/* For navbar css end */





/* Below css from vliv */


.signin-main {
  width: 100vw;
  height: 100vh;
  background-image: url("../assets/signInBackground.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.logo_img {
  width: 30%;
  height: 20%;
}


.signin-left p {
  color: white;
  font-size: 1.5rem;
  margin-top: 2rem;
  text-align: center;
}

/* .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 1.5rem !important;
} */

.pointer {
  cursor: pointer;
}


/* For canvas chart */
.canvasjs-chart-credit {
  display: none;
}










/* overriding mui  */

.css-n3fyjk-MuiDataGrid-root .MuiDataGrid-container--top [role="row"],
.css-mbboce-MuiTableRow-root,
.css-1ne8j0w {
  background-color: var(--common-bg-color) !important;
  border-bottom: 1px solid #b9e6fe !important;
  /* margin-bottom: 1.5px !important; */
  font-size: 16px !important;

}


.css-n3fyjk-MuiDataGrid-root .MuiDataGrid-row,
.css-ooo7gw .MuiDataGrid-row {
  /* color: #98a2b3 !important; */
  color: #1d2939 !important;
  background-color: white;
  font-size: 14px;
  margin-bottom: 2px;
}

.css-n3fyjk-MuiDataGrid-root .MuiDataGrid-virtualScrollerContent .MuiDataGrid-row:hover,
.css-ooo7gw .MuiDataGrid-row:hover {
  font-size: large;
  background-color: white;
  font-size: 15px;
  box-shadow: rgba(0, 0, 0, 0.23) 1px 2px 2px !important;
}


.css-n3fyjk-MuiDataGrid-root,
.css-ooo7gw {
  border-color: #b9e6fe !important;
  border-radius: 4px !important;
}

.css-rqglhn-MuiTable-root,
.css-1owb465 {
  border: 1px solid #b9e6fe !important;
  border-radius: 4px !important;
}

.css-rqglhn-MuiTable-root,
.css-1owb465 {
  border-collapse: separate !important;
}

.css-13xfq8m-MuiTabPanel-root {
  padding: 0 !important;
}

.css-hg18dx-MuiButtonBase-root-MuiTab-root,
.css-13cp4kv {
  font-size: 1rem !important;
  color: #98a2b3 !important;
}

.css-hg18dx-MuiButtonBase-root-MuiTab-root.Mui-selected,
.css-13cp4kv.Mui-selected {
  color: #026aa2 !important;
  background-color: white !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0.3, 0.3) !important;
}

.css-1897kd5 {
  padding: 0 !important;
}

/* here------------------ */

/* No changes needed for layout.css unless targeting MUI components specifically */

.master-heading {
  color: #37668f !important;
  font-weight: bold !important;
}

.view-left,
.view-right,
.application-status-box {
  padding: 6px 12px !important;
  border-radius: 10px !important;
  background-color: white !important;
}

.view-left:hover,
.view-right:hover,
.application-status-box:hover {
  box-shadow: rgba(0, 0, 0, 0.23) 0px 4px 4px !important;
}

.css-yq6j56 {
  padding: 12px;
}

.css-ooo7gw .MuiDataGrid-container--top [role="row"],
.css-ooo7gw .MuiDataGrid-container--bottom [role="row"] {
  background-color: var(--common-bg-color) !important;
  border-bottom: 1px solid #b9e6fe !important;
  /* margin-bottom: 1.5px !important; */
  font-size: 16px !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,
.ql-editor,
.css-qiwgdb,
.css-qiwgdb.MuiSelect-select,
.css-1x5jdmq,
.css-segi59,
.css-1x6bjyf-MuiAutocomplete-root,
.css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root,
.css-z3c6am-MuiFormControl-root-MuiTextField-root,
.css-aq7zae-MuiFormControl-root-MuiTextField-root,
.css-1ab2xsx,
.css-18col2x .MuiOutlinedInput-root,
.css-15kq27i {
  background-color: #fcfcfd !important;
  border-color: 1px solid #98a2b3 !important;
}

.css-1aquho2-MuiTabs-indicator {
  background: none !important;
}



.css-i4bv87-MuiSvgIcon-root,
.css-vubbuv {
  height: 20px !important;
  height: 20px !important;
}

.css-hcdzbm-MuiAutocomplete-root,
.css-fzem9i-MuiAutocomplete-root {
  padding: 0 !important;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root.Mui-disabled,
.css-1ujsas3.Mui-disabled {
  background-color: #98A2B3;
  color: #E4E7EC;
}


.css-ypiqx9-MuiDialogContent-root {
  padding: 0 !important;
}


/* --input  */

.css-hcdzbm-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input {
  padding: 0 !important;
}

.css-hcdzbm-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  padding: 10px 15px !important;
}




/* From below joText editor css added (Shyam bhai wala text editor ka css h he ye) */

.jodit-status-bar a.jodit-status-bar-link {
  background-color: transparent;
  color: rgba(0, 0, 0, .75);
  display: none;
}


/* document css start */
.section-document {
  height: 100%;
  padding: 1rem;
  background-color: var(--common-bg-color);
}

.common-icon {
  font-size: 1.8rem;
  color: var(--commonBtn-color);
  margin: auto;
}

.common-heading {
  font-size: 1.5rem;
  line-height: 1.6;
}

.common-para {
  font-size: 1rem;
  letter-spacing: 0.04rem;
  color: var(--icon-color);
}

.icon-img {
  width: 1.6rem;
  height: auto;
}

.icon-container {
  display: flex;
  gap: 1rem;
}

.action-icon--container.end.generate {
  height: 100%;
  align-items: flex-end;
}

.downlord-icon {
  height: 100%;
  display: flex;
  gap: 1rem;
  align-items: last flex-end;
  cursor: pointer;
  font-size: 1.4rem;
  color: var(--commonBtn-color);
}

.modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 50%;
  background-color: var(--common-bg-color);
  text-align: center;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 1rem;
  border-radius: 0.8rem;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 0.1rem solid var(--card-name-color);
}

/* .modal-body {} */



/* document css end */

/*progress bar css start */
.progress-bar-container {
  width: 100%;
  /* margin: 20px 0; */
}

.progress-bar {
  display: flex;
  height: 0.6rem;
  background-color: #e0e0e0;
  border-radius: 1rem;
  overflow: hidden;
}

.progress-segment {
  height: 100%;
}

.progress-bar-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.file-icons {
  display: flex;
  align-items: center;
}

.file-type {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.file-type img {
  height: 16px;
  width: 16px;
  margin-right: 5px;
}

/* progress bar css end */

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inputFieldIcon {
  position: relative;
}

.inputFieldIcon input {
  background: #fff;
  border: 0.1rem solid var(--card-name-color);
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
}

.inputFieldIcon .iconPosition {
  color: var(--search-pl-color);
  position: absolute;
  top: 0.9rem;
  right: 1.2rem;
}

.tableheader th {
  background-color: var(--brand-color);
  color: var(--btntext-color);
  padding: 1rem !important;
}

.tableheader th:first-child {
  border-top-left-radius: 1rem;
}

.tableheader th:last-child {
  border-top-right-radius: 1rem;
}

.action-icon--container {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  cursor: pointer;
}

.start {
  justify-content: flex-start;
}

.end {
  justify-content: flex-end;
}

.upload-container {
  width: 90%;
  margin: 0 auto;
}

.upload-file--container {
  border: 0.25rem dashed var(--brand-color);
  border-radius: 1.5rem;
  padding: 2rem 2.25rem;
  text-align: center;
  cursor: pointer;
}

.upload-file--container:hover {
  background-color: var(--btntext-color);
}

.upload-icon {
  width: 5.375rem;
  height: 6.375rem;
}

.upload-text {
  font-size: 1.25rem;
  font-weight: bold;
  color: var(--header-link-color);
  margin: 1rem 0rem;
}

.upload-instructions {
  font-size: 1.15rem;
  font-weight: 500;
  color: var(--card-name-color);
}

.upload-choose-file {
  color: var(--brand-color);
  text-decoration: underline;
  cursor: pointer;
}

.uploaded-img-container {
  max-height: 25rem;
  overflow-y: auto;
}

.upload-image {
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 1rem;
}

.files-container {
  display: flex;
  gap: 1rem;
  overflow: hidden;
  padding: 0.15rem 0.10rem;
}

.file-card {
  border: 0.1rem solid var(--card-color);
  border-radius: 1rem;
  width: 20rem;
  height: 17.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  background-color: var(--btntext-color);
}

.file-header {
  padding: 0.625rem 1rem;
  background-color: var(--common-bg-color);
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.file-body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-footer {
  padding: 0.625rem 1rem;
  background-color: var(--common-bg-color);
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.file-section,
.folder-section,
.user-section,
.date-time-section {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}


.file-name {
  font-size: 1rem;
  font-weight: 550;
  line-height: 22.4px;
  text-align: center;
  text-overflow: ellipsis;
  color: var(--brand-color);

}

.folder-section {
  margin-left: 2rem;
}

.folder-icon {
  color: var(--folder-color);
  font-size: 1.4rem;
}

.folder-text {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 22.4px;
  text-align: center;
}

.file-body img {
  width: 30%;
  height: auto;
}

.user-avtar {
  background-color: var(--brand-color);
  color: var(--btntext-color);
  padding: 0.6rem;
  border-radius: 50%;
  font-size: 0.75rem;
}

.user-name {
  font-size: 1rem;
  line-height: 21.86px;
}

.date-time-section {
  margin: 0.3rem 0rem 0rem 0rem;
}

.time {
  color: #475467;
}

.arrow-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem;
  margin: 0.2rem;
  border-radius: 0.5rem;
  box-shadow: 0px 1px 4px 0px var(--card-time-color);
}

.disabled-arrow-icon {
  cursor: not-allowed;
  box-shadow: 0px 0px 1px 0px var(--card-time-color);
}

.file-view--section {
  background-color: var(--common-bg-color);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.document-view--header {
  display: flex;
  gap: 32%;
}

.file-details-section {
  padding: 0.8rem;
  background-color: var(--sidebar-ho-text-color);
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.file-icon-section {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--file-bg-color);
  padding: 1.5rem;
  border-radius: 0.7rem;
}

.file-icon-section img {
  width: 40%;
  height: auto;
}

.document-header {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 27.32px;
  margin: 0.5rem 0;
}

.documentinfo {
  display: flex;
  font-size: 1rem;
  
}

.documentinfo span:nth-child(1) {
  width: 50%;
  color: var(--file-text-color);
}

.documentinfo span:nth-child(1)::after{
  content: ":";
  position: relative;
  right: 0rem;
}

.documentinfo span:nth-child(2){
  color: var(--header-link-color);
}

.document-icon{
  text-align: end;
  font-size: 1.3rem;
  color: var(--card-name-color);
}

.file-share--section{
  background-color: transparent;
  padding: 0rem;
}



/* folder css start */
.folder-main-section {
  background-color: var(--btntext-color);
}

.folder-tabs--section {
  display: flex;
  align-items: center;
  background-color: var(--tab-color);

  & .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: var(--brand-color);
    font-size: 1.25rem;
  }

  & .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer {
    margin: 0rem 0.625rem;
  }

  & .MuiButtonBase-root.MuiTab-root.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    font-family: "Hauramedium", sans-serif !important;
    text-transform: capitalize;
    letter-spacing: 0.05rem;
    background-color: var(--btntext-color);
    border-radius: 1rem;
  }
}

.left-folder--container {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0.5rem 0.5rem;
  border-radius: 6.25rem;
  text-overflow: ellipsis;
  cursor: pointer;

}

.left-folder--container:hover,
.sub-folder:hover {
  box-shadow: 0px 0px 3px 0px var(--card-name-color);
  background-color: var(--common-bg-color);
}

.folder-active {
  box-shadow: 0px 0px 3px 0px var(--card-name-color);
  background-color: var(--common-bg-color);
}

.closefolder-icon {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  transition: transform 0.3s linear;
}

.closefolder-icon.open {
  transform: rotate(90deg);
}

.delete-icon {
  margin-left: auto;
  color: var(--delete-btn-color);
}

.folder-image {
  width: 8%;
  height: auto;
}

.folder-name {
  font-size: 1.5rem;
}

.sub-folder--container {
  /* padding-top: 0.5rem;
  padding-left: 1rem; */
  margin: 0.5rem 0rem 0.5rem 2rem;
}

.sub-folderBtn {
  width: 100%;
  border: 2px dashed var(--brand-color);
  color: var(--brand-color);
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 6.25rem;
  padding: 0.2rem;
  font-family: "Haurabold", sans-serif;
  letter-spacing: 0.7px;
  line-height: 1.5;
  margin: 0.5rem 0rem;
  box-shadow: inset 0 0 0 1px var(--header-navlink-color);
  transition: all 0.3s linear;
}

.sub-folder {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.5rem;
  border-radius: 6.25rem;
  text-overflow: ellipsis;
  cursor: pointer;

  & .folder-name {
    font-size: 1rem;
  }
}

.section-subfolder {
  display: flex;
  justify-content: space-between;
}

.commonBtn.roundedBtn {
  font-size: 1.2rem;
  padding: 0.6rem 1.5rem;
  border-radius: 1.5rem;
  text-transform: capitalize;
}

.checkbox {
  padding: 1rem;
}

/* folder css end */

/* access management css start*/
.totalusersicon {
  border-radius: 50%;
 
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--brand-color);
}

.access-container {
  display: flex;
  gap: 0.4rem;
  justify-content: center;
  align-items: center;
}

.edit-access--folders {
  text-align: left;

  & .left-folder--container:hover {
    box-shadow: none;
    background-color: transparent;
  }
}

.current-folders {
  color: var(--card-name-color);
}

/* access management css end*/