/* header start css */
.headericons:hover {
    color: var(--icon-hover-color);
}

.MuiBox-root.css-19pu4hj a:hover {
    color: var(--icon-hover-color);
}

.file-card:hover {
    box-shadow: 0px 1px 4px 0px var(--card-time-color);
}

.document-icon:hover {
    color: var(--brand-color);
}

.totalusersicon:hover{
    text-decoration: underline;
}

/* header end css */